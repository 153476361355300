<template>
  <div>
    <HbPropertySelector
      v-if="level == 'Property'"
      id="facility"
      :items="facilityList"
      item-text="name"
      v-show="!isOPS"
      return-object
      v-validate="'required'"
      v-model.lazy="property"
      data-vv-scope="gate"
      data-vv-name="facility"
      data-vv-as="Property"
      :error="errors.has('gate.facility')"
    />

    <div class="mr-6">
      <hb-notification
        v-model="showIncomeAccountAlert"
        v-if="accountingToggleOn && showIncomeAccountAlert && level === 'Corporate'"
        @close="showIncomeAccountAlert = false"
        type="caution"
        :notDismissable="true"
      >
        There are some tax profile(s) with unconfigured gl account.
      </hb-notification>
      <hb-data-table-header v-if="level == 'Property' && !editTaxRate && isPropertySelected" class="mt-4">
        <template v-slot:actions>
          <hb-btn color="secondary" small @click="editTaxRates">Edit Tax Rates</hb-btn>
        </template>
      </hb-data-table-header>

      <hb-data-table-header v-if="level === 'Corporate'" class="mt-4">
        <template v-slot:description>
          Create and edit all of your tax profiles here.
        </template>
        <template v-slot:actions>
          <hb-btn color="secondary" small @click="addTaxProfile">Add New Tax Profile</hb-btn>
        </template>
      </hb-data-table-header>

      <!--
      <v-row class="mb-6 px-4" v-show="isOPS" v-if="level === 'Corporate'">
        <v-col cols="9" class="ma-0 pa-0 hb-text-light"> </v-col>
        <v-col cols="3" class="ma-0 pa-0 text-right">
          <hb-btn color="secondary">
            <a class="add-taxprofiles-btn" @click="addTaxProfile"
              >Add Tax Profile</a
            >
          </hb-btn>
        </v-col>
      </v-row>
      -->

      <hb-data-table
        :headers="headers"
        :items="taxProfiles"
        @click:row="editItem"
        v-if="level == 'Corporate'"
      >
        <template v-slot:item.gl_account_code="{ item }">
          <span v-if="item.gl_account_active == 0" class="pr-1">
          <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
          </span>
          <span :class="{'hb-text-error' : item.gl_account_active == 0}">{{item.gl_account_code}}</span>
        </template>
        <template v-slot:item.gl_account_name="{ item }">
          <span v-if="item.gl_account_active == 0" class="pr-1">
          <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
          </span>
          <span :class="{'hb-text-error' : item.gl_account_active == 0}">{{item.gl_account_name}}</span>
        </template>
        <template v-slot:item.name="{ item }">{{ item.name }}</template>
        <template v-slot:item.state="{ item }">{{ item.state }}</template>
        <template v-slot:item.tax_rate="{ item }"
          >{{ item.tax_rate }}%</template
        >
        <template v-slot:item.actions="{ item }">
          <hb-menu
            options
            align-right
          >
            <v-list>
              <v-list-item @click="editItem(item)">
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteItem(item)">
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </hb-menu>
        </template>
      </hb-data-table>

      <hb-empty-state
        v-if="level == 'Property' && !isPropertySelected"
        :message="'Please select a Property to continue.'"
        :showBtn="false"
      >
      </hb-empty-state>

      <hb-data-table
        :headers="taxRatesHeaders"
        :items="taxRatesData"
        disable-pagination
        hide-default-footer
        v-else-if="level == 'Property' && isPropertySelected"
      >
        <template v-slot:item.name="{ item }">{{ item.name }}</template>
        <template v-slot:item.gl_account_code="{ item }">
          <span v-if="item.gl_account_active == 0" class="pr-1">
          <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
          </span>
          <span :class="{'hb-text-error' : item.gl_account_active == 0}">{{item.gl_account_code}}</span>
        </template>
        <template v-slot:item.gl_account_name="{ item }">
          <span v-if="item.gl_account_active == 0" class="pr-1">
          <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
          </span>
          <span :class="{'hb-text-error' : item.gl_account_active == 0}">{{item.gl_account_name}}</span>
        </template>
        <template v-slot:item.tax_profile_id="{ item }">
          <v-select
            :items="taxProfilesData"
            :disabled="!isPropertySelected || !editTaxRate"
            v-model="item.tax_profile_id"
            placeholder="Select Tax Profile"
            item-text="name"
            item-value="id"
            flat
            dense
            single-line
            hide-details
            @change="getTaxProfileDetails(item)"
          >
          </v-select>
        </template>
        <!--                    <template v-slot:item.account_id="{ item }">-->
        <!--                      -->
        <!--                      <template v-if="item.account_id"> </template>-->
        <!--                        <span v-else>——</span>-->
        <!--                    </template>-->
        <template v-slot:item.tax_rate="{ item }">
          <span v-if="item.tax_profile_id && editTaxRate">
            <v-text-field
              placeholder="Enter Rate"
              v-validate="'required|max:45'"
              required
              id="tax_rate"
              name="tax_rate"
              v-model="item.tax_rate"
              single-line
              data-vv-as="tax rate"
              :error-messages="errors.first('tax_rate')"
              :hide-details="!errors.has('tax_rate')"
              class="ma-0 pa-0"
            >
            </v-text-field>
          </span>
          <span v-else-if="item.tax_rate">{{ item.tax_rate }}%</span>
          <span v-else>——</span>
        </template>

        <template v-slot:footer v-if="level == 'Property' && editTaxRate">
          <hb-bottom-action-bar @close="cancelTaxRatesChange">
            <template v-slot:right-actions>
              <hb-btn color="primary" @click="saveTaxRate">Save</hb-btn>
            </template>
          </hb-bottom-action-bar>
        </template>
      </hb-data-table>

    </div>
    
    <AddEditTaxProfiles
      v-model="showAddEdit"
      v-if="showAddEdit"
      :selected="selected"
      :accounts="accounts"
      @close="closeWindow"
      :isOPS="isOPS"
      :state="selectedPropertyState"
    />
    <hb-modal
      v-model="showDelete"
      v-if="showDelete"
      size="medium"
      title="Delete Tax Profile"
      @close="closeWindow"
      confirmation
      show-help-link
    >
      <template v-slot:content>
        <div class="py-4 px-6">
          Are you sure you want to delete the selected tax profile?
          <br /><br />
          This action cannot be undone.
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn color="destructive" @click="deleteConfirm">Delete</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import api from '../../../assets/api.js';
import AddEditTaxProfiles from './AddEditTaxProfiles';
import { mapGetters, mapActions } from 'vuex';
import settingsTabMixin from '../../../mixins/settingsTabMixin.js';
import TaxProfile from '../../../store/modules/tax_profile.js';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
export default {
    name: "TaxIndex",
    mixins:[settingsTabMixin, notificationMixin],
    data() {
        return {
            headers: [
                { text: "Profile Name", value: "name" },
                { text: "GL Code", value: "gl_account_code" },
                { text: "GL Name", value: "gl_account_name" },
                { text: "State", value: "state" },
                { text: "Rate", value: "tax_rate" },
                { text: "", value: "actions", align: "right", sortable: false, width: 10 }
            ],
            taxRatesHeaders: [
                { text: "Tax Category", value: "name" },
                { text: "GL Code", value: "gl_account_code" },
                { text: "GL Name", value: "gl_account_name" },
                { text: "Tax Profile", value: "tax_profile_id", width: 273 },
                // { text: "Account", value: "account_id"},
                { text: "Rate", value: "tax_rate", width: 163}
            ],
            defaulTaxRates: [
                {id: null, name: 'Storage Rent', type: 'storage', tax_profile_id: null, tax_profile: '', gl_account_code: '', gl_account_name: '', tax_rate: ''},
                {id: null, name: 'Parking', type: 'parking', tax_profile_id: null, tax_profile: '', gl_account_code: '', gl_account_name: '', tax_rate: ''},
                {id: null, name: 'Merchandise', type: 'merchandise', tax_profile_id: null, tax_profile: '', gl_account_code: '', gl_account_name: '', tax_rate: ''},
                {id: null, name: 'Fees', type: 'fee', tax_profile_id: null, tax_profile: '', gl_account_code: '', gl_account_name: '', tax_rate: ''},
                {id: null, name: 'Residential', type: 'residential', tax_profile_id: null, tax_profile: '', gl_account_code: '', gl_account_name: '', tax_rate: ''},
                {id: null, name: 'Insurance', type: 'insurance', tax_profile_id: null, tax_profile: '', gl_account_code: '', gl_account_name: '', tax_rate: ''},
                {id: null, name: 'Auctions', type: 'auction', tax_profile_id: null, tax_profile: '', gl_account_code: '', gl_account_name: '', tax_rate: ''},
                {id: null, name: 'Deposit', type: 'deposit', tax_profile_id: null, tax_profile: '', gl_account_code: '', gl_account_name: '', tax_rate: ''}
            ],
            showAddEdit: false,
            showDelete: false,
            selected: {},
            property: null,
            taxProfilesData: [],
            taxRatesData: [],
            editTaxRate: false,
            spaceTypes: [],
            checkForSpaceTypes: ['storage', 'residential', 'parking'],
            fetchedTaxRates: [],
            addTaxRateCategory: null,
            accounts: []
        }
    },
  components: {
    AddEditTaxProfiles
  },
  props: {
    level: {
      type: String,
      default: ""
    },
    isOPS: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
      taxProfiles: "taxProfileStore/taxProfiles",
      propertyTaxRates: "taxProfileStore/propertyTaxRates",
      accountingSettings: "accountingStore/accountingSettings",
      onboardingData: "onboardingTabsStore/getOnboardingData",
      showIncomeAccountAlert: "taxProfileStore/showIncomeAccountAlert"
    }),
    isPropertySelected() {
      if (this.isOPS) {
        this.property = this.onboardingData.property;
      }
      return this.property && this.property.id ? true : false;
    },
    selectedPropertyState() {
      if (!this.isOPS) {
        return this.property && this.property.Address
          ? this.property.Address.state
          : null;
      } else {
        if (
          this.onboardingData.property &&
          this.onboardingData.property.Address
        ) {
          return this.onboardingData.property &&
            this.onboardingData.property.Address
            ? this.onboardingData.property.Address.state
            : this.onboardingData.property.Address.state;
        } else {
          return null;
        }
      }
    },
    accountingToggleOn(){
      return Object.keys(this.accountingSettings).length && this.accountingSettings.toggleAccounting == "1"? true : false;
    }
  },
  async created() {
    await this.fetchAccounting();

    if (this.level == "Corporate") {
      this.getTaxProfiles();
    } else {
      if (this.isPropertySelected) {
        api
          .get(this, api.PROPERTIES + this.property.id + "/tax-rates")
          .then(r => {});
      }
      this.taxProfilesData = JSON.parse(JSON.stringify(this.taxProfiles));
      this.taxRatesData = JSON.parse(JSON.stringify(this.defaulTaxRates));
    }
    this.getAccountingSettings();
  },
  methods: {
    ...mapActions({
      getTaxProfiles: "taxProfileStore/getTaxProfiles",
      getPropertyTaxRates: "taxProfileStore/getPropertyTaxRates",
      getAccountingSettings: "accountingStore/getAccountingSettings",
      deleteTaxProfile: "taxProfileStore/deleteTaxProfile"
    }),
    async fetchAccounting() {
      let p = await api.get(this, api.ACCOUNTING + "accounts");
      this.accounts = p.accounts;
    },
    accountName(tax) {
      let account = this.accounts.find(a => a.id === tax);
      if (!account) return "";
      return account.name;
    },
    accountCode(tax) {
      let account = this.accounts.find(a => a.id === tax);
      if (!account) return "";
      return account.code;
    },
    editTaxRates() {
      this.editTaxRate = true;
    },
    saveTaxRate() {
      let taxRatesFormData = [];
      this.taxRatesData.forEach(el => {
        if (el.tax_profile_id && el.tax_profile_id != "new") {
          taxRatesFormData.push({
            tax_rate: parseFloat(el.tax_rate),
            tax_profile_id: el.tax_profile_id,
            type: el.type
          });
        }
      });

      api
        .post(this, api.PROPERTIES + this.property.id + "/tax-rates", {
          tax_rates: taxRatesFormData
        })
        .then(r => {
          this.editTaxRate = false;
          this.addTaxRateCategory = null;
          if (this.isOPS) {
            let currentLocalDate = this.$options.filters.formatDateTimeCustom(
              new Date(),
              "MMM DD, YYYY @ h:mma."
            );
            this.showMessageNotification({ type: 'success', description: 'The Tax Profiles have been saved on ' + currentLocalDate });
          }
        });
    },
    cancelTaxRatesChange() {
      this.getSelectedSpaceTypesTaxRates(this.spaceTypes);
      this.setFetchedTaxRates();
      this.editTaxRate = false;
      this.addTaxRateCategory = null;
    },
    getSelectedSpaceTypesTaxRates(spaceTypes) {
      this.taxRatesData = [];
      this.defaulTaxRates.forEach(tr => {
        if (
          this.spaceTypes.length &&
          this.checkForSpaceTypes.includes(tr.type)
        ) {
          this.spaceTypes.forEach(element => {
            if (element.type == tr.type) {
              this.taxRatesData.push(JSON.parse(JSON.stringify(tr)));
            }
          });
        } else {
          this.taxRatesData.push(JSON.parse(JSON.stringify(tr)));
        }
      });
    },
    setFetchedTaxRates() {
      if (this.propertyTaxRates.length) {
        this.taxRatesData.forEach(tr => {
          this.propertyTaxRates.forEach(ftr => {
            if (tr.type == ftr.type) {
              let taxProfile = this.taxProfilesData.find(p => p.id === ftr.tax_profile_id);
              tr.tax_profile_id = ftr.tax_profile_id;
              tr.id = ftr.id;
              tr.gl_code = ftr.gl_code;
              tr.tax_rate = ftr.tax_rate;
              tr.gl_account_code = taxProfile.gl_account_code;
              tr.gl_account_name = taxProfile.gl_account_name;
            }
          });
        });
      }
    },
    getTaxProfileDetails(item) {
      if (item.tax_profile_id == "new") {
        this.addTaxRateCategory = null;
        this.addTaxRateCategory = item;
        this.showAddEdit = true;
      } else {
        api.get(this, api.TAX_PROFILE + item.tax_profile_id).then(r => {
          item.gl_account_code = r.tax_profile.gl_account_code;
          item.gl_account_name = r.tax_profile.gl_account_name;
          item.gl_code = r.tax_profile.gl_code;
          item.tax_rate = r.tax_profile.tax_rate;
        });
      }
    },
    closeWindow(taxProfileData) {
      if (this.addTaxRateCategory) {
        this.addTaxRateCategory.tax_profile_id = taxProfileData.id;
        this.addTaxRateCategory.gl_code = taxProfileData.gl_code;
        this.addTaxRateCategory.tax_rate = taxProfileData.tax_rate;
      }
      this.selected = {};
      this.showAddEdit = false;
      this.showDelete = false;
    },
    addTaxProfile() {
      this.selected = {};
      this.showAddEdit = true;
    },
    editItem(item) {
      this.selected = item;
      this.showAddEdit = true;
    },
    deleteItem(item) {
      this.selected = item;
      this.showDelete = true;
    },
    async deleteConfirm() {
      let data = this.selected;
      await this.deleteTaxProfile(data);
      this.closeWindow();
    }
  },
  watch: {
    async property() {
      if(this.editTaxRate) this.editTaxRate = false;
      if (this.isPropertySelected) {
        await this.getPropertyTaxRates({ property_id: this.property.id });
        await api
          .get(this, api.PROPERTIES + this.property.id + "/tax-profiles")
          .then(r => {
            this.taxProfilesData = [];
            this.taxProfilesData = JSON.parse(JSON.stringify(r.tax_profiles));
            this.taxProfilesData.push({
              id: "new",
              name: "Create New Tax Profile"
            });
          });
        api
          .get(this, api.PROPERTIES + this.property.id + "/space-types")
          .then(r => {
            this.spaceTypes = r.space_types;
            this.getSelectedSpaceTypesTaxRates(r.space_types);
            this.setFetchedTaxRates();
          });
        this.taxProfilesData.push({
          id: "new",
          name: "Create New Tax Profile"
        });
      }
    },
    taxProfiles() {
      if (this.isPropertySelected) {
        api
          .get(this, api.PROPERTIES + this.property.id + "/tax-profiles")
          .then(r => {
            this.taxProfilesData = [];
            this.taxProfilesData = JSON.parse(JSON.stringify(r.tax_profiles));
            this.taxProfilesData.push({
              id: "new",
              name: "Create New Tax Profile"
            });
          });
        this.taxProfilesData.push({
          id: "new",
          name: "Create New Tax Profile"
        });
      }
    },
    addTaxRateCategory() {
      if (!this.addTaxRateCategory.id) {
        this.addTaxRateCategory.tax_profile_id = "";
      }
    }
  }
};
</script>
<style scoped>
.top-border {
  border-top: 1px solid #dfe3e8;
}
.truncate {
  width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-taxprofiles-btn {
  color: black !important;
  text-decoration: none;
}

.tax-table {
  margin-bottom: 50px;
}
.corporate-property-tab {
  margin-bottom: 12px !important;
}
</style>
<style lang="scss">
.facility-dropdown {
  .v-input__slot {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
  }
}
</style>
