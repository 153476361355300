<template>
    <div>

    <hb-form label="Pin Length">
      {{connection.Credentials.pin_format}}
    </hb-form>

    <hb-bottom-action-bar
      :cancel-off="connection.Credentials.connected"
      @close="$emit('cancel')"
      :top-border="false"
      v-resize="setAuditCollapse"
      ref="bottomRemoveBar"
      >
      <template v-slot:left-actions v-if="connection.Credentials.connected && connection.modified_at && connection.modified_by_fullname">
        <hb-tooltip v-if="collapseAudit">
          <template v-slot:item>
            <v-row no-gutters align="center">
              <hb-icon 
                mdi-code="mdi-account-clock-outline"
                small
                color="#101318"  
                class="mr-2 ml-4 mt-0 mb-1"
              >
              </hb-icon>
            </v-row>
          </template>
          <template v-slot:body>
            {{connection.modified_by_fullname}}<br>{{editTime}}
          </template>
        </hb-tooltip>
        <v-row no-gutters align="center" v-else>
          <hb-icon 
            mdi-code="mdi-account-clock-outline"
            small
            color="#101318" 
            class="mr-2 ml-4 mt-0 mb-1"
          >
          </hb-icon>
          <v-col class="py-0 my-0 hb-font-caption">
            {{connection.modified_by_fullname + " (" + editTime + ")"}}
          </v-col>
        </v-row>
      </template>
      <template v-slot:right-actions>
        <hb-btn
          v-if="!connection.Credentials.connected"
          :disabled="!property_id || savingInProgress"
          :loading="savingInProgress"
          @click="save"
        >
          Connect to DMS
        </hb-btn>
        <hb-btn
          v-if="connection.Credentials.connected"
          color="destructive"
          :disabled="!connection.access_id || removeInProgress"
          :loading="removeInProgress"
          @click="$emit('confirmRemoveAccess')"
        >
          Remove Integration
        </hb-btn>
      </template>
    </hb-bottom-action-bar>
    </div>
  </template>
  <script type="text/babel">
  import api from "../../../assets/api.js";
  import { EventBus } from "../../../EventBus.js";
  import { notificationMixin } from  '../../../mixins/notificationMixin.js';
  export default {
    name: "DMS",
	mixins: [ notificationMixin ],
    data() {
      return {
        connection: {
          property_id: "",
          access_id: "",
          Credentials: {
            site_key: "",
            modified_by_fullname: "",
            modified_at: "",
            connected: false,
            pin_format: '8'
          }
        },
        savingInProgress: false,
        collapseAudit: false
      };
    },
    mounted() {
      if(this.$props.access.Credentials) {
        this.connection = this.$props.access;
      }
    },
    computed: {
      editTime(){
          if(this.connection.modified_at) return this.$options.filters.formatLocalDateTimeServices(this.connection.modified_at);
          return '';
      }
    },
    methods: {
      async fetchData(connected) {
        let r = await api.get(this, api.PROPERTIES + this.property_id + "/access");
  
        this.connection = r.access;
        if(this.connection.access_id) {
          this.$emit('gateSelection', this.connection.Credentials.connected);
        }
      },
      async save() {
  
        let status = await this.$validator.validateAll("gate");
        if (!status) {
          this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
          return;
        } 
        
        this.savingInProgress = true;
  
        var data = {
          property_id: this.$props.property_id,
          access_id: this.$props.selectedGateAccessId,
          Credentials:{
            pin_format: this.connection.Credentials.pin_format,
          }
        };
  
        try {
  
          let r = await api.post(this, api.ACCESS_CONTROL, data);
          let s = await this.fetchData(true);
  
          if(this.connection.Credentials.connected){
  
            this.showMessageNotification({ type: 'success', description: 'You have successfully connected to the DMS access control integration.'});
          } else {
  
            this.showMessageNotification({ type: 'error', description: 'There was an error connecting to the DMS access control integration. Verify your credentials and try again or contact Customer Success for assistance.'});
          }
  
        } catch( err ) {
  
          this.showMessageNotification({ description: err });
        }
  
        this.savingInProgress = false;
      },
      setAuditCollapse() {
        let bottomBar = this.$refs.bottomRemoveBar;
        if (bottomBar && bottomBar.$el.offsetWidth > 0 && bottomBar.$el.offsetWidth < 570) {
          this.collapseAudit = true
        } else {
          this.collapseAudit = false
        }
      }
    },
    watch: {
      access() {
        this.connection = this.access;
      }
    },
    props: ["property_id", "access", "selectedGateAccessId", "removeInProgress"]
  };
  </script>