<template>
  <div class="section-content ml-0 pt-0 pr-0 mt-n2">
    <div v-if="!$props.facilityID">
        <HbPropertySelector
            id="facility"
            :items="facilityList"
            :disabled="isLoading($options.name) || !isFacilities"
            :loading="isLoading($options.name) || !isFacilities"
            item-text="name"
            item-value="id"
            v-validate="'required'"
            v-model.lazy="property_id"
            data-vv-scope="gate"
            data-vv-name="facility"
            data-vv-as="Property"
            v-show="!isOPS"
            :error="errors.has('gate.facility')"
        />
    </div>

    <div class="primary-section-content mr-6">

        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        
        <status @resetStatus="successClear('saveConfigTemplate')" v-if="successHas('saveConfigTemplate')" :message="successGet('saveConfigTemplate')" status="success"></status>
        <status @resetStatus="errorClear('saveConfigTemplate')" v-if="errorHas('saveConfigTemplate')" :message="errorGet('saveConfigTemplate')" status="error"></status>
        
        <v-row no-gutters v-if="property_id">
            <v-col cols="12" no-gutters>
                <hb-card no-title>
                    <!--
                    <v-col class="pl-0 pr-0 pt-4 pb-4">
                        <div class="lease-heading pl-6">Lease Configuration</div>
                    </v-col>
                    <v-divider></v-divider>
                    -->
                    <v-col class="px-4 pt-4">
                        Choose the lease you would like to use for each type of space.
                    </v-col>
                    <div>
                        <v-col class="px-4 py-0">
                            <v-radio-group v-model="view" row class="mt-0">
                                <v-radio label="Storage" value="storage" v-if="getTemplatesLength('storage') > 0"></v-radio>
                                <!-- <v-radio label="Residential" value="residential" v-if="getTemplatesLength('residential') > 0"></v-radio>
                                <v-radio label="Commercial" value="commercial" v-if="getTemplatesLength('commercial') > 0"></v-radio> -->
                                <v-radio label="Parking" value="parking" v-if="getTemplatesLength('parking') > 0"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-divider></v-divider>

                            <hb-form
                                v-if=" (view == 'residential' || (availableViews.length == 1 && availableViews[0] == 'residential') && getTemplatesLength('residential') > 1 )
                                    || (view == 'storage'     || (availableViews.length == 1 && availableViews[0] == 'storage')     && getTemplatesLength('storage') > 1 )
                                    || (view == 'commercial'  || (availableViews.length == 1 && availableViews[0] == 'commercial')  && getTemplatesLength('commercial') > 1 )
                                    || (view == 'parking'  || (availableViews.length == 1 && availableViews[0] == 'parking')  && getTemplatesLength('parking') > 1 )
                                "
                                label="Lease Configuration"
                                :editable="hasPermission('view_edit_templates')"
                                :active.sync="active"
                                @cancel="cancel"
                                @save="save"
                                :empty="!selectedTemplate.Template.name"
                            >
                                <template v-slot:display>
                                    {{ selectedTemplate.Template.name }}
                                </template>
                                <template v-slot:edit>
                                    <hb-select
                                    v-model="preSelectedTemplate"
                                    v-validate="'required'"
                                    :clearable="false"
                                    :items="filteredTemplates"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    id="name"
                                    name="name"
                                    data-vv-scope="form_1"
                                    data-vv-as="Lease Type"
                                    :error="errors.has('form_2.lease_type')"
                                    placeholder="Select Lease Type"
                                    ></hb-select>
                                </template>
                            </hb-form>
                            <!--
                            <v-col class="field-label pl-4" cols="3">
                                <div>
                                    <span class="font-weight-medium">Lease Configuration</span>
                                </div>
                            </v-col>
                            <v-col class="pa-0" cols="9">
                                <hoverable-edit-field
                                :activeEditing="activelyEditing == 'lease_configuration'"
                                :hasPermissionToEdit="hasPermission('view_edit_templates')"
                                name="lease_configuration"
                                >
                                <template slot="editingMode">
                                    <v-row class="pa-0 ma-0">
                                        <v-col class="pa-0 ma-0" cols="6">
                                            <v-select
                                                dense
                                                hide-details
                                                :items="filteredTemplates"
                                                item-text="name"
                                                item-value="id"
                                                return-object
                                                v-model="preSelectedTemplate"
                                                v-validate="'required'"
                                                id="name"
                                                name="name"
                                                placeholder="Choose Template">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template slot="readMode">
                                    {{ selectedTemplate.Template.name }}
                                </template>
                                </hoverable-edit-field>
                            </v-col>
                            -->
                            <!-- <v-col md="3" class="key-label hb-table-label pl-6">
                                <div class="pt-1 font-15px">Lease Configuration</div>
                            </v-col>
                            <v-col md="9 pl-5 pr-5 d-flex">
                                <div class="form-input-container pr-4">
                                    <v-select
                                        dense
                                        hide-details
                                        :items="filteredTemplates"
                                        item-text="name"
                                        item-value="id"
                                        return-object
                                        v-model="preSelectedTemplate"
                                        v-validate="'required'"
                                        id="name"
                                        name="name"
                                        placeholder="Choose Template">
                                    </v-select>
                                </div>
                                <hb-btn :disabled="isLoading('saveConfigTemplate')" :large="true" @click="save()" color="primary">Save</hb-btn>
                                <span v-show="isLoading('saveConfigTemplate')" >
                                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                                </span>
                            </v-col> -->

                        <div v-if="selectedTemplate && selectedTemplate.Template">
                            <div class="hb-font-header-3-medium pa-4">Configured Lease for {{ selectedTemplate.unit_type | capitalize}} Spaces</div>
                            <v-divider></v-divider>
                        </div>


                        <div v-if="selectedTemplate && selectedTemplate.Template && selectedTemplate.Template.id">
                            <!-- <v-row class="top-border ma-0 pa-0 hb-table-row">
                                <v-col md="3" class="key-label hb-table-label pl-6">
                                    <div class="font-15px">Default Configuration</div>
                                </v-col>
                                <v-col md="9 pl-5">
                                    {{ !selectedTemplate.id ? 'Yes' : 'No'}}
                                </v-col>
                            </v-row> -->

                            <!-- turning this off for now because it is not reflecting correct information -->
                            <!--
                            <hb-form label="Default Configuration">
                                {{ !selectedTemplate.Template.id ? 'Yes' : 'No' }}
                            </hb-form>
                            -->
                            <GeneralConfigurations :template="selectedTemplate.Template" :read-mode="true" :show-space-type="false" :facilityLevel="true" ref="GeneralConfigurations"></GeneralConfigurations>
                            <MoveInTransferConfigurations v-if="selectedTemplate.Template && selectedTemplate.Template.id" :template="selectedTemplate.Template" :read-mode="true" :facilityLevel="true" ref="MoveInTransferConfigurations"></MoveInTransferConfigurations>
                            <move-out :template="selectedTemplate.Template" :read-mode="true" :facilityLevel="true" />
                            <v-divider></v-divider>
                            
                            <BillingConfigurations :template="selectedTemplate.Template" :read-mode="true" :facilityLevel="true" />
                            
                            <!-- <v-row class="top-border ma-0 pa-0 hb-table-row">
                                <v-col md="3" class="key-label hb-table-label pl-6">
                                    <div class="font-15px">Name</div>
                                </v-col>
                                <v-col md="9 pl-5">
                                    <div>
                                        {{selectedTemplate.Template.name}}<br />
                                        <span class="subdued">{{selectedTemplate.Template.description}}</span>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row class="top-border ma-0 pa-0 hb-table-row" v-if="selectedTemplate.Template.lease_type == 'Month to Month'">
                                <v-col md="3" class="key-label hb-table-label pl-6">
                                    <div class="font-15px">Lease Type</div>
                                </v-col>
                                <v-col md="9 pl-5">
                                    <div class="pt-1">
                                        {{selectedTemplate.Template.lease_type}}
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row class="top-border ma-0 pa-0 hb-table-row" v-if="selectedTemplate.Template.lease_type == 'Fixed Length'">
                                <v-col md="3" class="key-label hb-table-label pl-6">
                                    <div class="font-15px">Lease Duration</div>
                                </v-col>
                                <v-col md="9 pl-5">
                                    <div class="pt-1">
                                        {{selectedTemplate.Template.lease_duration}} {{selectedTemplate.Template.lease_duration_type}}
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row class="top-border ma-0 pa-0 hb-table-row">
                                <v-col md="3" class="key-label hb-table-label pl-6">
                                    <div class="font-15px">Bill On</div>
                                </v-col>
                                <v-col md="9 pl-5">
                                    <div class="pt-1">
                                        {{selectedTemplate.Template.bill_day}}
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row class="top-border ma-0 pa-0 hb-table-row">
                                <v-col md="3" class="key-label hb-table-label pl-6">
                                    <div class="font-15px">Email Statement</div>
                                </v-col>
                                <v-col md="9 pl-5">
                                    <div class="pt-1">
                                        {{selectedTemplate.Template.email_statement ? 'Yes' : 'No'}}
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row class="top-border ma-0 pa-0 hb-table-row">
                                <v-col md="3" class="key-label hb-table-label pl-6">
                                    <div class="font-15px">Terms</div>
                                </v-col>
                                <v-col md="9 pl-5">
                                    <div class="pt-1">
                                        {{selectedTemplate.Template.terms}}
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row class="top-border ma-0 pa-0 hb-table-row">
                                <v-col md="3" class="key-label hb-table-label pl-6">
                                    <div class="font-15px">Charges to Lease</div>
                                </v-col>
                                <v-col md="9 pl-5">
                                    <div class="pt-1">
                                        <span v-for="(service, index) in getselectedTemplate('lease')" :key="index">
                                            {{service.Product.name + (getselectedTemplate('lease').length - 1 != index ? ',' : '') }}
                                        </span>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row class="top-border ma-0 pa-0 hb-table-row">
                                <v-col md="3" class="key-label hb-table-label pl-6">
                                    <div class="font-15px">Charges to Reserve</div>
                                </v-col>
                                <v-col md="9 pl-5">
                                    <div class="pt-1">
                                        <span v-for="(service, index) in getselectedTemplate('reservation')" :key="index">
                                            {{service.Product.name + (getselectedTemplate('reservation').length - 1 != index ? ',' : '') }}
                                        </span>
                                    </div>
                                </v-col>
                            </v-row> -->

                            <!-- <v-row class="top-border ma-0 pa-0 hb-table-row">
                                <v-col md="3" class="key-label hb-table-label pl-6">
                                    <div class="font-15px">Insurance Options</div>
                                </v-col>
                                <v-col md="9 pl-5">
                                    <div class="pt-1">
                                        <span v-for="(service, index) in getselectedTemplate('insurance')" :key="index">
                                            {{service.Insurance.name + (getselectedTemplate('insurance').length - 1 != index ? ',' : '') }}
                                        </span>
                                    </div>
                                </v-col>
                            </v-row> -->

                            <!-- <v-row class="top-border ma-0 pa-0 hb-table-row">
                                <v-col md="3" class="key-label hb-table-label pl-6">
                                    <div class="font-15px">Checklist Items</div>
                                </v-col>
                                <v-col md="9 pl-5">
                                    <div class="pt-1">
                                        {{ selectedTemplate.Template && selectedTemplate.Template.Checklist ? selectedTemplate.Template.Checklist.length : 0 }} Checklist Items
                                    </div>
                                </v-col>
                            </v-row> -->

                            <!-- <div class="slide-out-tenant-header bg-white grey-border py-0">
                                <div class="slide-out-info ml-0">
                                    <v-row>
                                        <v-col cols="12" :class="{'px-0 text-center' : !selectedTemplate.id, 'text-right' : selectedTemplate.id}">
                                            <div class="info-section" v-show="!selectedTemplate.id">
                                                This template is inherited as the default template on this account.
                                            </div>
                                            <hb-btn :disabled="isLoading('deleteConfigTemplate')" v-show="selectedTemplate.id" color="destructive" @click="removeTemplate()">Remove Template</hb-btn>
                                            <span v-show="isLoading('deleteConfigTemplate')" >
                                                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                                            </span>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div> -->
                        </div>

                        <div
                            v-else-if="selectedTemplate && selectedTemplate.Template && !selectedTemplate.Template.id"
                            class="pa-6"
                        >
                            <hb-empty-state message="There is no configured lease saved for this space type. Please add a Lease Configuration above to continue."></hb-empty-state>
                        </div>

                    </div>

                </hb-card>
            </v-col>
        </v-row>

        <hb-empty-state
            v-else
            message="Please select a Property to continue."
        >
        </hb-empty-state>
    </div>
  </div>
</template>

<script type="text/babel">
import api from "../../../assets/api.js";
import Loader from '../../assets/CircleSpinner.vue';
import Status from '../../includes/Messages.vue';
import GeneralConfigurations from '../configureLease/GeneralConfigurations.vue';
import MoveInTransferConfigurations from '../configureLease/MoveInTransferConfigurations.vue';
import BillingConfigurations from '../configureLease/BillingConfigurations.vue';
import MoveOut from '../configureLease/MoveOut.vue';
import HoverableEditField from "../../assets/HoverableEditField.vue";
import { EventBus } from '../../../EventBus.js';
import { mapGetters } from 'vuex';
import { notificationMixin } from "../../../mixins/notificationMixin.js";

export default {
    name: "LeaseConfiguration",
    data() {
        return {
            activelyEditing: '',
            tabValue: '',
            property_id: "",
            templates:[],
            view: "",
            residential: {
                id: '',
                unit_type: 'residential',
                Template: {
                    id: '',
                    name: ''
                }
            },
            storage: {
                id: '',
                unit_type: 'storage',
                Template: {
                    id: '',
                    name: ''
                }
            },
            commercial: {
                id: '',
                unit_type: 'commercial',
                Template: {
                    id: '',
                    name: ''
                }
            },
            parking: {
                id: '',
                unit_type: 'parking',
                Template: {
                    id: '',
                    name: ''
                }
            },
            selected_storage: {
                id: '',
                name:''
            },
            selected_residential: {
                id: '',
                name:''
            },
            selected_commercial: {
                id: '',
                name:''
            },
            selected_parking: {
                id: '',
                name:''
            },
            isFacilities: false,
            active: '',

        };
    },
    mixins: [notificationMixin],
    components: { Loader, Status, GeneralConfigurations, MoveInTransferConfigurations, MoveOut, HoverableEditField, BillingConfigurations},
    async created() {
        EventBus.$on('activeEdit', this.setActiveEditName);
        EventBus.$on('saveData', this.save);
        if (this.$props.facilityID) {
            this.property_id = this.$props.facilityID;
        }
        await this.fetchTemplates();
        this.isFacilities = this.facilityList.length;
    },
    destroyed() {
        EventBus.$off('activeEdit', this.setActiveEditName);
        EventBus.$off('saveData', this.save);
        this.closeMessageNotification();
    },
    computed: {
        ...mapGetters({
            facilityList: 'propertiesStore/filtered',
            onboardingData: "onboardingTabsStore/getOnboardingData",
            hasPermission: 'authenticationStore/rolePermission'
        }),
        
        filteredTemplates(){
            return this.templates.filter(t => t.unit_type == this.view)
        },
        selectedTemplate(){
            if(this.view == 'residential' || (this.availableViews.length == 1 && this.availableViews[0] == 'residential' )){
                return this.residential;
            }
            if(this.view == 'storage' || (this.availableViews.length == 1 && this.availableViews[0] == 'storage' )){
                return this.storage;
            }
            if(this.view == 'commercial' || (this.availableViews.length == 1 && this.availableViews[0] == 'commercial' )){
                return this.commercial;
            }
            if(this.view == 'parking' || (this.availableViews.length == 1 && this.availableViews[0] == 'parking' )){
                return this.parking;
            }
        },
        availableViews(){
            var views = [];
            this.templates.map(t => {
                if(views.indexOf(t.unit_type) < 0 ) views.push(t.unit_type)
            });
            return views;
        },
        preSelectedTemplate : {
            get: function() {
				switch (this.view) {
                    case 'residential':
                        return this.selected_residential
                        break;
                    case 'storage':
                        return this.selected_storage
                        break;
                    case 'commercial':
                        return this.selected_commercial
                        break;
                    case 'parking':
                        return this.selected_parking
                        break;
                }
			},
			set: function(value) {
				switch (this.view) {
                    case 'residential':
                        this.selected_residential = value;
                        break;
                    case 'storage':
                        this.selected_storage = value;
                        break;
                    case 'commercial':
                        this.selected_commercial = value;
                        break;
                    case 'parking':
                        this.selected_parking = value;
                        break;
                }
			}
        }
    },
    methods: {
        setActiveEditName(name) {
            this.activelyEditing = name;
        },
        getTemplatesLength(type){
            return this.templates.filter(t => t.unit_type == type).length;
        },
        fetchTemplates(){
            api.get(this, api.TEMPLATES).then(r => {
                this.templates = r.templates;
            });
        },
        transformGetData(data) {
            const transObj = { ...data };

            transObj.products = {
                movein: [{}],
                transfer: [{}],
                service: [{}]
            };
            
            let p = {};
            let prodsValue = data.products;
            for (let i = 0; i < prodsValue.length; i++) {   
                p[prodsValue[i].category_type] = p[prodsValue[i].category_type] || [];
                prodsValue[i].category_type && p[prodsValue[i].category_type].push(prodsValue[i]);

                if(prodsValue[i].category_type) {
                    transObj.products[prodsValue[i].category_type] = p[prodsValue[i].category_type];
                }
            }

            transObj.checklist = {
                lease: [],
                autopay: [],
                'enroll-coverage': [],
                'deny-coverage': [],
                vehicle: [],
                military: [],
                other: [],
                nameOnly: []
            }

            let d = {};
            const docsValues = data.checklist;
            
            for (let i = 0; i < docsValues.length; i++) {
                d[docsValues[i].document_tag || 'nameOnly'] = d[docsValues[i].document_tag || 'nameOnly'] || [];
                d[docsValues[i].document_tag || 'nameOnly'].push(docsValues[i]);
                transObj.checklist[docsValues[i].document_tag || 'nameOnly'] = d[docsValues[i].document_tag || 'nameOnly'];
            }

            return transObj;
        },
        fetchData(){
            api.get(this, api.PROPERTIES + this.property_id + '/templates').then(r => {

                this.residential = r.templates['residential'];
                this.storage.Template = this.transformGetData(r.templates['storage'].Template); //r.templates['storage'];
                this.commercial = r.templates['commercial'];
                this.parking.Template = this.transformGetData(r.templates['parking'].Template); //r.templates['parking'];

                if(this.residential && this.residential.Template){
                    this.selected_residential = this.residential.Template;
                }
                if(this.storage && this.storage.Template) {
                    this.selected_storage = this.storage.Template
                }
                if(this.commercial && this.commercial.Template) {
                    this.selected_commercial = this.commercial.Template;
                }
                if(this.parking && this.parking.Template) {
                    this.selected_parking = this.parking.Template;
                }
            });
        },
        save(){
            let _this = this;
            let type = this.view;
            let data = {};
            let save = {
                unit_type: type
            }

            if(type == 'residential'){
                data = this.residential;
                save.lease_template_id = this.selected_residential.id;

            } else if(type == 'storage'){
                data = this.storage;
                save.lease_template_id = this.selected_storage.id;

            } else if(type == 'commercial'){
                data = this.commercial;
                save.lease_template_id = this.selected_commercial.id;

            } else if(type == 'parking'){
                data = this.parking;
                save.lease_template_id = this.selected_parking.id;
            }

            api.post(_this, api.PROPERTIES + this.property_id + '/templates',  save, 'saveConfigTemplate').then(r => {
                _this.activelyEditing = '';
                this.showMessageNotification({ id: this.$options.name, type: "success", description: 'Lease template has been successfully updated.' });
                this.active = '';
                this.fetchData();
            });
        },
        cancel(){
            this.active = '';
            this.preSelectedTemplate = this.selectedTemplate.Template;
        },
        removeTemplate(){
            api.delete(this, api.PROPERTIES + this.property_id + '/templates/', this.selectedTemplate.id, 'deleteConfigTemplate').then(r => {
                  this.fetchData();
              }).catch((err) => {
                    this.showMessageNotification({ id: this.$options.name, type: "error", description: err });
                });;

        },
        resetValues(){
            this.residential = {
                id: '',
                unit_type: 'residential',
                Template: {
                    id: '',
                    name: ''
                }
            };
            this.storage = {
                id: '',
                unit_type: 'storage',
                Template: {
                    id: '',
                    name: ''
                }
            }
            this.commercial = {
                id: '',
                unit_type: 'commercial',
                Template: {
                    id: '',
                    name: ''
                }
            }
            this.parking = {
                id: '',
                unit_type: 'parking',
                Template: {
                    id: '',
                    name: ''
                }
            }
            this.selected_storage = {
                id: '',
                name:''
            };
            this.selected_residential = {
                id: '',
                name:''
            };
            this.selected_commercial = {
                id: '',
                name:''
            };
            this.selected_parking = {
                id: '',
                name: ''
            }
        },
        getselectedTemplate(type){
            return  this.selectedTemplate 
                    && this.selectedTemplate.Template
                    && this.selectedTemplate.Template.Services 
                    ? this.selectedTemplate.Template.Services.filter(service => service.service_type == type)
                    : []
        },
    },
    props: ["facilityID","isOPS","level"],
    watch:{
        property_id(){
            this.resetValues();
            this.fetchData();
        }
    },
    mounted(){
        if(this.isOPS && this.level === 'Property' && this.onboardingData.propertyData.length > 0){
            this.property_id = this.onboardingData.propertyData[0].property_id;
        } 
    }
};
</script>

<style lang="scss" scoped>
.interaction-text {
  padding-bottom: 10px;
}
.interaction-text {
  padding: 16px 0 16px 24px;
  background-color: white;
}
.interaction-section {
  margin-bottom: 8px;
  border-radius: 4px;
  box-shadow: 0px 2px 0px rgba(11, 18, 29, 0.1),
    0px 2px 2px rgba(11, 18, 29, 0.1), 0px 1px 2px rgba(11, 18, 29, 0.1);
}
.interaction-section-body {
  height: 0;
  overflow: hidden;
  background: white;
}
.interaction-section-body.open {
  height: auto;
  border-top: 2px solid #dce8ef;
}
.interaction-section h2 {
  font-size: 14px;
  line-height: 24px;
  color: #101318;
  background-color: #ffffff;
  box-sizing: border-box;
  font-weight: normal;
  margin: 0;
}
.lease-heading{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #101318;
}
.remove-btn {
  background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
  border: 1px solid #C4CDD5;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
  border-radius: 4px;
}
.config-lease{
    font-weight: 500;
    font-size: larger;
}
</style>